/***********************************************************************************************************************
 *
 * VUEX STATE MUTATIONS
 *
 * SYNC!!!
 *
 **********************************************************************************************************************/
import Vue from "vue";
import {defaultState} from "./";
import {persistState, resetState} from "../handlers/persistantState";

const MUTATIONS = {
    /*
     * SET/UNSET "requesting"
     * @param {Object} state - Vuex $store.state
     * @param {Boolean} payload
     */
    SET_REQUESTING: (state, payload) => {
        state.requesting = payload;
    },

    /*
     * SET authError
     * @param {Object} state - Vuex $store.state
     * @param {String} payload
     */
    SET_AUTH_ERROR: (state, payload) => {
        state.authError = payload;
    },

    /*
     * SET authError
     * @param {Object} state - Vuex $store.state
     * @param {String} payload
     */
    SET_SERVER_ERROR: (state, payload) => {
        state.serverError = payload;
    },

    /*
     * RESET state errors
     * @param {Object} state - Vuex $store.state
     * @param {String} payload
     */
    RESET_ERRORS: (state) => {
        state.authError = "";
        state.serverError = "";
        persistState(state);
    },

    /*
     * SET page status
     * @param {Object} state - Vuex $store.state
     * @param {String} payload.section
     * @param {String} payload.status
     */
    SET_SECTION_STATUS: (state, payload) => {
        console.log("setting page status to ", payload);
        state.sections.forEach((section, index) => {
            if (section.name === payload.section) {
                section.status = payload.status;
                Vue.set(state.sections, index, section);
            }
        });
        persistState(state);
    },

    /*
     * SET AUTH METERNUMBER
     * @param {Object} state - Vuex $store.state
     * @param {String} payload - meterNumber for auth
     */
    SET_METER_NUMBER: (state, payload) => {
        state.meterNo = payload;
    },

    /*
     * SET AUTH CUSTOMERNUMBER
     * @param {Object} state - Vuex $store.state
     * @param {String} payload - customerNumber for auth
     */
    SET_CUSTOMER_NUMBER: (state, payload) => {
        state.customerNo = payload;
    },

    /*
     * CUSTOMER AND METER DATA
     * @param {Object} state - Vuex $store.state
     * @param {String} payload.customerNo
     * @param {String} payload.meterNo
     * @param {String} payload.customerId
     * @param {Number} payload.clientId
     * @param {Array} payload.meters
     * @param {Object} payload.customerDetails
     */
    SET_CUSTOMER_DATA: (state, payload) => {
        console.log("readings from server: ", payload.meterReadings);
        if (payload.customerId) state.customerId = payload.customerId;
        if (payload.clientId) state.clientId = payload.clientId;
        if (payload.meters) state.meters = payload.meters;
        if (payload.meterReadings) {
            let hasDismountedItem = false;
            payload.meterReadings.forEach((reading, index) => {
                if (reading.dismounted && reading.meterReadingDate) {
                    const date = reading.meterReadingDate.split(".");
                    reading.meterReadingDate = new Date(date[2], date[1] - 1, date[0], 12, 0).toISOString();
                    Vue.set(state.meterReadings, index, reading);
                    hasDismountedItem = true;
                }
            });
            if (hasDismountedItem) state.dateType = "individual";
        }
        if (payload.meterReadings) state.meterReadings = payload.meterReadings;
        if (payload.customerDetails) state.customerDetails = payload.customerDetails;
        if (payload.meterImages) state.meterImages = payload.meterImages;
        if (payload.allowImageUpload) state.allowImageUpload = payload.allowImageUpload;
        if (payload.allowedFileTypes.length) state.allowedFileTypes = payload.allowedFileTypes;
        if (payload.imageMaxFileSize) state.imageMaxFileSize = payload.imageMaxFileSize;
        if (payload.allowEmailSubmission) state.allowEmailSubmission = payload.allowEmailSubmission;
        persistState(state);
    },

    /*
     * RESET STATE
     * @param {Object} state - Vuex $store.state
     */
    RESET: (state) => {
        // acquire initial state
        const s = defaultState();
        Object.keys(s).forEach(key => {
            state[key] = s[key];
        });
        resetState();
    },

    /*
     * SET DATE TYPE
     * @param {Object} state - Vuex $store.state
     * @param {String} payload
     */
    SET_DATE_TYPE: (state, payload) => {
        state.dateType = payload;
        persistState(state);
    },

    /*
     * SET GLOBAL READING DATE
     * @param {Object} state - Vuex $store.state
     * @param {String} payload
     */
    SET_GLOBAL_READING_DATE: (state, payload) => {
        state.globalReadingDate = payload;
        persistState(state);
    },

    /*
     * SET METER READING REASON
     * @param {Object} state - Vuex $store.state
     * @param {String} payload
     */
    SET_READING_REASON: (state, payload) => {
        state.readingReason = payload;
        persistState(state);
    },

    /*
     * SET METER READING IMPLAUSIBLE REASON
     * @param {Object} state - Vuex $store.state
     * @param {String} payload.meterNumber
     * @param {Number} payload.meterItemNumber
     * @param {String} payload.reason
     */
    SET_IMPLAUSIBLE_REASON: (state, payload) => {
        state.meterReadings.forEach((meterItem, index) => {
            if (
                meterItem.meterNumber === payload.meterNumber
                && meterItem.meterItemNumber === payload.meterItemNumber
            ) {
                meterItem.implausibleReason = payload.reason;
                Vue.set(state.meterReadings, index, meterItem);
            }
        });
        persistState(state);
    },

    /*
     * SET METER READING IMPLAUSIBLE OTHER REASON
     * @param {Object} state - Vuex $store.state
     * @param {String} payload.meterNumber
     * @param {Number} payload.meterItemNumber
     * @param {String} payload.otherReason
     */
    SET_IMPLAUSIBLE_REASON_OTHER: (state, payload) => {
        state.meterReadings.forEach((meterItem, index) => {
            if (
                meterItem.meterNumber === payload.meterNumber
                && meterItem.meterItemNumber === payload.meterItemNumber
            ) {
                meterItem.implausibleReasonOther = payload.otherReason;
                Vue.set(state.meterReadings, index, meterItem);
            }
        });
        persistState(state);
    },

    /*
     * SET METER READING IMPLAUSIBLE OTHER REASON
     * @param {Object} state - Vuex $store.state
     * @param {String} payload.meterNumber
     * @param {Number} payload.meterItemNumber
     */
    RESET_IMPLAUSIBLE_REASON: (state, payload) => {
        state.meterReadings.forEach((meterItem, index) => {
            if (
                meterItem.meterNumber === payload.meterNumber
                && meterItem.meterItemNumber === payload.meterItemNumber
            ) {
                meterItem.implausibleReason = "";
                meterItem.implausibleReasonOther = "";
                Vue.set(state.meterReadings, index, meterItem);
            }
        });
        persistState(state);
    },

    /*
     * SET INDIVIDUAL READING DATE
     * @param {Object} state - Vuex $store.state
     * @param {String} payload.meterNumber
     * @param {ISOString} payload.meterReadingDate
     */
    SET_INDIVIDUAL_READING_DATE: (state, payload) => {
        state.meterReadings.forEach((meterItem, index) => {
            if (meterItem.meterNumber === payload.meterNumber) {
                meterItem.meterReadingDate = payload.meterReadingDate;
                Vue.set(state.meterReadings, index, meterItem);
            }
        });
        persistState(state);
    },

    /*
     * SET READING VALUE
     * @param {Object} state - Vuex $store.state
     * @param {String} payload.meterNumber
     * @param {String} payload.meterItemNumber
     * @param {Number} payload.readingValue
     */
    SET_READING_VALUE: (state, payload) => {
        state.meterReadings.forEach((meterItem, index) => {
            if (
                meterItem.meterNumber === payload.meterNumber
                && meterItem.meterItemNumber === payload.meterItemNumber
            ) {
                meterItem.meterReadingValue = payload.readingValue;
                Vue.set(state.meterReadings, index, meterItem);
            }
        });
        persistState(state);
    },

    /*
     * SET READING VALUE
     * @param {Object} state - Vuex $store.state
     * @param {String} payload
     */
    SET_PRINT_FILE: (state, payload) => {
        state.printFile = payload;
        persistState(state);
    },

    /*
     * SET UPLOAD PROGRESS OF IMAGE
     * @param {Object} state - Vuex $store.state
     * @param {String} payload.meterNumber
     * @param {Number} payload.percentage
     */
    SET_IMAGE_PROGRESS: (state, payload) => {
        state.meterImages.forEach( (meter, index) => {
            if (meter.meterNumber === payload.meterNumber) {
                meter.progress = payload.percentage;
                Vue.set(state.meterImages, index, meter);
            }
        });
    },

    /*
     * SET ERROR MESSAGE FOR IMAGE UPLOADS
     * @param {Object} state - Vuex $store.state
     * @param {String} payload.meterNumber
     * @param {Array} payload.errors
     */
    SET_IMAGE_ERRORS: function(state, payload) {
        state.meterImages.forEach( (meter, index) => {
            if (meter.meterNumber === payload.meterNumber) {
                meter.errors = payload.errors;
                Vue.set(state.meterImages, index, meter);
            }
        });
        persistState(state);
    },

    /*
     * SET ORIGINAL FILENAME OF IMAGE
     * @param {Object} state - Vuex $store.state
     * @param {String} payload.meterNumber
     * @param {String} payload.name
     */
    SET_IMAGE_FILENAME: function(state, payload) {
        state.meterImages.forEach( (meter, index) => {
            if (meter.meterNumber === payload.meterNumber) {
                meter.originalFileName = payload.name;
                Vue.set(state.meterImages, index, meter);
            }
        });
        persistState(state);
    },

    /**
     * SET IMAGE SERVER PATHS
     * @param {Object} state - Vuex $store.state
     * @param {String} payload.meterNumber
     * @param {String} payload.file
     * @param {String} payload.thumb
     */
    SET_IMAGE_PATHS: function(state, payload) {
        state.meterImages.forEach( (meter, index) => {
            if (meter.meterNumber === payload.meterNumber) {
                meter.filePath = payload.file;
                meter.thumb = payload.thumb;
                Vue.set(state.meterImages, index, meter);
            }
        });
        console.log("set path to ", state.meterImages);
        persistState(state);
    },

    /**
     * SET IMAGE STATE TO "DELETING"
     * @param {Object} state - Vuex $store.state
     * @param {String} payload.meterNumber
     * @param {boolean} payload.isDeleting
     */
    SET_IMAGE_DELETING: function(state, payload) {
        state.meterImages.forEach( (meter, index) => {
            if (meter.meterNumber === payload.meterNumber) {
                meter.isDeleting = payload.isDeleting;
                Vue.set(state.meterImages, index, meter);
            }
        });
    },

    /**
     * UPDATE CUSTOMER EMAIL
     * @param {Object} state - Vuex $store.state
     * @param {String} payload
     */
    UPDATE_EMAIL: function(state, payload) {
        state.email = payload;
        persistState(state);
    },

    /**
     * UPDATE CUSTOMER EMAIL
     * @param {Object} state - Vuex $store.state
     * @param {Boolean} payload
     */
    SET_SUBMITTING_EMAIL: function(state, payload) {
        state.emailSubmitting = payload;
    },

    /*
     * SET ERROR MESSAGE FOR EMAIL SUBMISSIONS
     * @param {Object} state - Vuex $store.state
     * @param {Array} payload
     */
    SET_EMAIL_ERRORS: function(state, payload) {
        state.emailErrors = payload;
    },

    /*
     * SET ERROR MESSAGE FOR EMAIL SUBMISSIONS
     * @param {Object} state - Vuex $store.state
     * @param {Boolean} payload
     */
    SET_EMAIL_DONE: function(state, payload) {
        state.emailSubmissionDone = payload;
        persistState(state);
    },

};

export default MUTATIONS;
