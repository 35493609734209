<script>
/******************************************************************************
 * Image Requirements
 *****************************************************************************/
export default {
    computed: {
        fileTypes () { return this.$store.getters.allowedFileTypes },
        maxSize () {
            return this.$store.getters.imageMaxFileSize / 1000;
        }
    }
}
</script>

<template>
    <ul>
        <li
            class="file-size"
            :title="`Maximal zulässige Dateigröße: ${maxSize.toFixed(1)} MB`">
            Max<br>{{ maxSize.toFixed(1) }} MB
        </li>
        <li
            class="file-type"
            v-for="fileType in fileTypes"
            :key="fileType"
            :title="`Zulässiger Dateityp: ${fileType.toUpperCase()}`"
            :aria-label="`Zulässiger Dateityp: ${fileType.toUpperCase()}`">
            <span>{{ fileType.toUpperCase() }}</span>
        </li>
    </ul>
</template>

<style lang="scss" scoped>
    $bg: map-get($client, "meterImageRequirementsBg");
    $bgDark: darken($bg, 7%);

    ul {
        display: flex;
        flex-wrap: wrap;

        padding: 0;
        margin: 0;

        list-style: none;
    }

    .file-type {
        position: relative;

        width: 60px;
        height: 80px;
        padding: #{map-get($sizes, "base") / 3};
        margin: 0 #{map-get($sizes, "base") / 3} #{map-get($sizes, "base") / 3} 0;
        clip-path: polygon(0% 0%, 74% 0%, 100% 20%, 100% 100%, 0% 100%);

        background: $bg;
        border-radius: map-get($client, "borderRadius");

        text-align: center;

        span {
            position: absolute;
            top: 45%;
            right: 0;
            left: 0;

            border: #{map-get($sizes, "border")} solid $bgDark;

            background: palette("grey", "white");

            font-weight: 400;
        }
    }

    .file-size {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;

        width: 60px;
        height: 80px;
        border: #{map-get($sizes, "border")} solid $bgDark;
        margin: 0 #{map-get($sizes, "base") / 3} #{map-get($sizes, "base") / 3} 0;
        clip-path: polygon(0% 0%, 74% 0%, 100% 20%, 100% 100%, 0% 100%);

        border-radius: map-get($client, "borderRadius");

        text-align: center;
    }

    .file-type::after,
    .file-size::after {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 80%;
        left: 74%;

        background: $bgDark;

        content: " ";
    }
</style>
