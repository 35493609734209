<script>
/******************************************************************************
 * ZUSAMMENFASSUNG
 *****************************************************************************/
import PageSection from "@/components/PageSection/PageSection";
import CustomerData from "@/components/CustomerData/CustomerData";
import ReadingSummary from "./ReadingSummary/ReadingSummary";
import NavRow from "@/components/NavRow/NavRow";
import RequestProgressing from "@/components/RequestProgressing/RequestProgressing";
import ListItem from "./ListItem";
import Icon from "@/components/Icon/Icon";
import router from "@/router";
const EVENTS = ["online", "offline", "load"];
export default {
    data: function () {
        return {
            isOnline: window.navigator.onLine || true
        }
    },
    components: { PageSection, CustomerData, ListItem, ReadingSummary, NavRow, Icon, RequestProgressing },
    computed: {
        readingReason () { return this.$store.getters.readingReason; },
        requesting () { return this.$store.getters.requesting; },
        serverError () { return this.$store.getters.serverError; },
        submitDisabled () { return !this.isOnline || this.requesting}
    },
    methods: {
        doSubmit () {
            let data = {
                clientId: this.$store.getters.clientId,
                customerId: this.$store.getters.customerId,
                customerNo: this.$store.getters.customerNo,
                reason: this.$store.getters.readingReason,
                readings: []
            };
            const meterImages = this.$store.getters.meterImages;
            this.$store.getters.meterReadings.forEach(meter => {
                const meterData = this.$store.getters.getMeteringItemsByMeterNumber(meter.meterNumber)[0];
                const meterLocation = `${meterData.meterLocationStreet} ${
                    meterData.meterLocationStreetNumber
                }, ${meterData.meterLocationPoCode} ${meterData.meterLocationCity}`;
                const meterMediaType = meterData.meterMediaType;
                const meterImage = meterImages.find(image => image.meterNumber === meter.meterNumber);
                if (meter.meterReadingValue) {
                    data.readings.push({
                        meterNumber: meter.meterNumber,
                        meterItemNumber: meter.meterItemNumber,
                        meterReadingValue: meter.meterReadingValue,
                        meterReadingDate: this.$store.getters.dateType === "global"
                            ? this.$store.getters.globalReadingDate
                            : meter.meterReadingDate,
                        meterLocation,
                        meterMediaType,
                        meterImagePath: meterImage.filePath,
                        meterImageName: meterImage.originalFileName,
                        meterUnit: meterData.meterUnit,
                        implausibleReason: meter.implausibleReason ? meter.implausibleReason : undefined,
                        implausibleReasonOther: meter.implausibleReasonOther ? meter.implausibleReasonOther : undefined
                    });
                }
            });
            console.log("readings: ", data.readings);
            console.log(this.$store.getters.meterImages);
            this.$store.dispatch("SUBMIT_READING", data);
        },
        goBack () { router.push({name: "meterReading"}); },
        updateOnlineStatus: function() {
            if (typeof window.navigator.onLine === "undefined") {
                this.isOnline = true;
            } else {
                this.isOnline = window.navigator.onLine;
            }
        }
    },
    mounted() {
        EVENTS.forEach(event => window.addEventListener(event, this.updateOnlineStatus));
    },
    beforeDestroy() {
        EVENTS.forEach(event => window.removeEventListener(event, this.updateOnlineStatus));
    }
}
</script>

<template>
    <main>
        <page-section hdl="Zusammenfassung">
            <p>
                Bitte überprüfen Sie Ihre Angaben. Wenn alles richtig ist, schließen Sie Ihre
                Mitteilung des Zählerstands an uns mit dem Button rechts unten „Zählerstände melden“ ab.
            </p>
            <p>
                Falls nötig, können Sie Ihre Daten jetzt ändern, indem Sie in der
                Navigation oben zum Punkt "Zählerstände" navigieren oder unten den Button "Zurück" verwenden.
            </p>
        </page-section>
        <customer-data />
        <page-section
            class="reading-data"
            hdl="Allgemeine Ablesedaten">
            <list-item
                label="Ablesegrund"
                :value="readingReason" />
        </page-section>
        <reading-summary />
        <div
            v-if="serverError"
            class="server-error">
            <span v-html="serverError" />
        </div>
        <nav-row class="button-row">
            <template slot="left">
                <button
                    type="button"
                    @click="goBack">
                    <icon
                        class="icon-prev"
                        name="prev" />
                    Zurück
                </button>
            </template>
            <template slot="right">
                <button
                    type="button"
                    @click="doSubmit"
                    :disabled="submitDisabled"
                    :aria-disabled="submitDisabled">
                    <request-progressing v-if="requesting" />
                    Zählerstände melden
                    <icon
                        class="icon-next"
                        name="save" />
                </button>
            </template>
        </nav-row>
    </main>
</template>

<style lang="scss" scoped>
    .reading-data.reading-data {
        margin: map-get($sizes, "base") 0;
    }

    .icon-next {
        float: right;

        margin: 7px 0 0 5px;
    }

    .icon-prev {
        float: left;

        margin: 7px 5px 0 0;
    }

    .button-row.button-row {
        padding-top: map-get($sizes, "base");
    }

    .server-error {
        margin: 0 0 map-get($sizes, "base");

        color: palette("state", "error");

        &::first-letter { font-size: 1.6em; }

        @include respond-to("medium") { text-align: center; }
    }
</style>
