<script>
/******************************************************************************
 * METER Image Preview Component
 *****************************************************************************/
import Icon from "@/components/Icon/Icon";
import RequestProgressing from "@/components/RequestProgressing/RequestProgressing";
export default {
    props: {
        thumb: {type: String, required: true},
        meterNumber: {type: String, required: true}
    },
    components: { Icon, RequestProgressing },
    computed: {
        currentMeter () {
            return this.$store.getters.meterImages.find(
                meter => meter.meterNumber === this.meterNumber
            );
        },
        isDeleting () {
            return this.currentMeter.isDeleting;
        }
    },
    methods: {
        doDelete () {
            this.$store.dispatch("DELETE_IMAGE", {
                customerId: this.$store.getters.customerId,
                customerNumber: this.$store.getters.customerNo,
                meterNumber: this.meterNumber,
                filePath: this.currentMeter.filePath
            });
        }
    }
}
</script>

<template>
    <div
        v-if="thumb"
        class="wrapper">
        <img
            class="thumbnail"
            :src="thumb"
            alt="Vorschau des hochgeladenen Bildes">
        <aside class="actions">
            <label :for="`readingImage${meterNumber}`">
                <icon name="add_photo" />
                Anderes Bild
            </label>
            <button
                @click="doDelete"
                :disabled="isDeleting"
                :aria-disabled="isDeleting">
                <icon name="delete" />
                Bild löschen
                <request-progressing v-if="isDeleting" />
            </button>
        </aside>
    </div>
</template>

<style lang="scss" scoped>
    .wrapper {
        display: flex;
        align-items: flex-start;
        flex-direction: column;

        width: 100%;
        max-width: #{map-get($breakpoints, "small")};

        @include respond-to("small") {
            flex-direction: row;
        }
    }

    .thumbnail {
        padding: 4px;
        border: map-get($sizes, "border") solid palette("grey", "iron");

        border-radius: map-get($client, "borderRadius");
    }

    .actions {
        margin-top: #{map-get($sizes, "base") / 2};

        @include respond-to("small") {
            margin: 0 0 0 #{map-get($sizes, "base") / 2};
        }

        label,
        button {
            display: flex;
            align-items: center;

            padding: #{map-get($sizes, "base") / 2.5} map-get($sizes, "base");
            border: #{map-get($sizes, "border")} solid map-get($client, "inputBorder");
            margin: 0 0 #{map-get($sizes, "base") / 4} 0;

            background-color: map-get($client, "inputBg");
            color: map-get($client, "inputText");
            border-radius: map-get($client, "borderRadius") 0 0 map-get($client, "borderRadius");
            box-shadow:
                inset 2px 2px 0 map-get($client, "appBg"),
                inset -2px 2px 0 map-get($client, "appBg"),
                inset 2px -2px 0 map-get($client, "appBg"),
                inset -2px -2px 0 map-get($client, "appBg");
            cursor: pointer;

            font-family: map-get($fonts, "system");
            font-size: map-get($sizes, "base");
            font-weight: 300;

            transition:
                background-color map-get($animation-speeds, "fast") linear,
                border-color map-get($animation-speeds, "fast") linear,
                color map-get($animation-speeds, "fast") linear;

            &[disabled] {
                cursor: not-allowed;
            }

            svg {
                margin-right: #{map-get($sizes, "base") / 5};

                &.circular {
                    width: 24px;
                    height: 24px;
                    margin: 0 0 0 #{map-get($sizes, "base") / 5};
                }
            }

            &:hover {
                background-color: darken(map-get($client, "inputBg"), 7%);
                color: palette("grey", "black");
                outline: 0;
                border-color: darken(map-get($client, "inputBg"), 7%);
            }
        }
    }
</style>
