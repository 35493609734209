<script>
/******************************************************************************
 * Error Message for Image
 *****************************************************************************/
export default {
    props: {
        errorMessages: {
            type: Array,
            default: function() { return [] }
        }
    },
    computed: {
        displayMessages () {
            return this.errorMessages.map(msg => `⚠ ${msg}`);
        }
    }
}
</script>

<template>
    <div class="error-message">
        <header>😡 Fehler!</header>
        <ul>
            <li
                v-for="msg in displayMessages"
                :key="msg">{{ msg }}</li>
        </ul>
    </div>
</template>


<style lang="scss" scoped>
    .error-message {
        font-weight: 400;

        header {
            color: palette("state", "error");
        }
    }

    ul {
        padding: 0;
        margin: 0;

        list-style: none;
    }

    li {
        color: palette("state", "warning");
    }
</style>
