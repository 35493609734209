<script>
/******************************************************************************
 * Reason for implausible meter reading Component
 *****************************************************************************/
import FormRow from "@/components/FormRow/FormRow";
import DropDown from "@/components/DropDown/DropDown";
import {LENGTH_IMPLAUSIBLE_REASON_OTHER} from "@/config";
export default {
    data() {
        return {
            availableReasons: [
                {name: "Längere Abwesenheit", value: "Längere Abwesenheit"},
                {name: "Neues Gerät", value: "Neues Gerät"},
                {name: "Bedarfsänderung", value: "Bedarfsänderung"},
                {name: "Alter Wert beruht auf Schätzung", value: "Alter Wert beruht auf Schätzung"},
                {name: "Sonstiges", value: "Sonstiges"},
            ],
            selectedValue: this.$store.getters.implausibleReasonByMeter(
                {meterNumber: this.meterNumber, meterItemNumber: this.meterItemNumber}
            ),
            otherReason: this.$store.getters.implausibleReasonOtherByMeter(
                {meterNumber: this.meterNumber, meterItemNumber: this.meterItemNumber}
            )
        }
    },
    components: { FormRow, DropDown },
    props: {
        meterNumber: {type: String, required: true},
        meterItemNumber: {type: Number, required: true}
    },
    methods: {
        methodToRunOnSelect(payload) {
            let data = {
                meterNumber: this.meterNumber,
                meterItemNumber: this.meterItemNumber,
                reason: payload.value
            };
            return this.$store.dispatch("SET_IMPLAUSIBLE_REASON", data);
        },
        onChangeOtherReason() {
            let data = {
                meterNumber: this.meterNumber,
                meterItemNumber: this.meterItemNumber,
                otherReason: this.otherReason
            };
            return this.$store.dispatch("SET_IMPLAUSIBLE_REASON_OTHER", data);
        }
    },
    computed: {
        showOtherReason () {
            return this.$store.getters.implausibleReasonByMeter(
                {meterNumber: this.meterNumber, meterItemNumber: this.meterItemNumber}
            ) === "Sonstiges";
        },
        maxLengthOther () { return LENGTH_IMPLAUSIBLE_REASON_OTHER },
        charsRemaining () {
            const inputLength = this.otherReason.length || 0;
            return LENGTH_IMPLAUSIBLE_REASON_OTHER - inputLength;
        },
        showReasonHint () {
            return !this.$store.getters.implausibleReasonByMeter(
                {meterNumber: this.meterNumber, meterItemNumber: this.meterItemNumber}
            );
        },
        showOtherReasonHint () {
            return !this.$store.getters.implausibleReasonOtherByMeter(
                {meterNumber: this.meterNumber, meterItemNumber: this.meterItemNumber}
            );
        }
    }
}
</script>

<template>
    <div class="implausible-reason">
        <form-row
            label="Grund für unplausiblen Zählerstand"
            :ref-id="`implausibleReason-${meterNumber}-${meterItemNumber}`"
            class="implausible-reason">
            <template
                class="meter-reading"
                slot="input">
                <drop-down
                    :options="availableReasons"
                    :selected-value="selectedValue"
                    @updateOption="methodToRunOnSelect" />
                <p
                    class="hint"
                    v-if="showReasonHint">
                    Ihr Zählerstand ist unplausibel. Bitte korrigieren Sie den Zählerstand
                    oder geben Sie den Grund für den unplausiblen Zählerstand ein.
                </p>
            </template>
        </form-row>
        <form-row
            v-if="showOtherReason"
            label="Sonstiger Grund / Kommentar"
            :ref-id="`implausibleReasonOther-${meterNumber}-${meterItemNumber}`"
            class="implausible-reason">
            <template slot="input">
                <input
                    type="text"
                    :id="`otherReason-${meterNumber}-${meterItemNumber}`"
                    :name="`otherReason-${meterNumber}-${meterItemNumber}`"
                    class="other-reason"
                    placeholder="Sonstiger Grund"
                    :maxlength="maxLengthOther"
                    v-model="otherReason"
                    @keyup="onChangeOtherReason()"
                    @change="onChangeOtherReason()"
                    required>
                <p
                    class="hint"
                    v-if="showOtherReasonHint">
                    Bitte geben Sie den Sonstigen Grund an.
                </p>
            </template>
            <template slot="text">
                <small class="chars-remaining">
                    Sie können noch <strong>{{ charsRemaining }}</strong> Zeichen eingeben.
                </small>
            </template>
        </form-row>
    </div>
</template>



<style lang="scss" scoped>
    .implausible-reason {
        margin-bottom: #{map-get($sizes, "base") * 0.5};
    }

    input[type=text].other-reason {
        width: 100%;
        border-right-width: 0;

        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        @media all and (min-width: #{map-get($breakpoints, "small")}) {
            width: #{map-get($breakpoints, "small")};
        }
    }

    .chars-remaining {
        display: block;

        margin-top: -#{map-get($sizes, "base") / 3};

        strong { color: palette("brand", "dark"); }
    }

    .hint {
        color: palette("state", "error");

        font-weight: 500;
    }
</style>
