<script>
/******************************************************************************
 * Kundendaten
 *****************************************************************************/
import PageSection from "@/components/PageSection/PageSection";
import Panel from "@/components/Panel/Panel";
export default {
    components: {
        PageSection,
        Panel
    },
    computed: {
        customerNo () { return this.$store.getters.customerNo; },
        customerFullName () {
            const cust = this.$store.getters.customerDetails;
            let name = "";
            if (!cust) return;
            if (cust.KND_ANREDE && typeof cust.KND_ANREDE === "string") name = cust.KND_ANREDE;
            if (cust.KND_VORNAME && typeof cust.KND_VORNAME === "string") name += ` ${cust.KND_VORNAME}`;
            if (cust.KND_NAME && typeof cust.KND_NAME === "string") name += ` ${cust.KND_NAME}`;
            if (cust.KND_NAMENSZUSATZ && typeof cust.KND_NAMENSZUSATZ === "string") name += ` ${cust.KND_NAMENSZUSATZ}`;
            return name;
        }
    }
}
</script>

<template>
    <panel hdl="Kundendaten">
        <ul class="clearfix">
            <li class="label">Kundennummer:</li>
            <li class="value">{{ customerNo }}</li>
            <li class="label">Vertragspartner:</li>
            <li class="value">{{ customerFullName }}</li>
        </ul>
    </panel>
</template>

<style lang="scss" scoped>
    ul {
        padding: 0;
        margin: 0;

        list-style: none;

        > li { display: block; }
    }

    $rowHeight: 24px;

    .label,
    .value {
        display: block;

        margin-bottom: 1rem;

        @include respond-to("medium") {
            float: left;

            margin-bottom: 0 map-get($sizes, "base") 0 0;

            line-height: $rowHeight;
        }
    }

    @include respond-to("medium") {
        .label {
            width: #{map-get($sizes, "base") * 12};

            font-weight: 400;
        }
        .value { width: calc(100% - #{map-get($sizes, "base") * 12}); }
    }
</style>
