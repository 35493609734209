<script>
/******************************************************************************
 * Header Component
 *****************************************************************************/
import NavBar from "./NavBar/NavBar";
import TitleBar from "./TitleBar/TitleBar";
export default {
    components: {
        NavBar,
        TitleBar
    }
}
</script>

<template>
    <header>
        <title-bar />
        <nav-bar />
    </header>
</template>

<style lang="scss" scoped>
    header {
        position: fixed;
        top: 0;
        z-index: z("header");

        width: 100%;
        max-width: map-get($breakpoints, "large");
        margin: 0 auto;

        background: map-get($client, "bodyBg");

        @include respond-to("large") {
            height: auto;
            padding-top: map-get($sizes, "desktopMargin");
        }
    }
</style>
