<script>
/******************************************************************************
 * METER Image Component
 *****************************************************************************/
// TODO: online check
import FormRow from "@/components/FormRow/FormRow";
import Icon from "@/components/Icon/Icon";
import RequestProgressing from "@/components/RequestProgressing/RequestProgressing";
import MeterImagePreview from "./MeterImagePreview";
import UploadProgress from "./UploadProgress";
import ImageError from "./ImageError";
import ImageRequirements from "./ImageRequirements";
export default {
    components: { FormRow, Icon, RequestProgressing, MeterImagePreview, UploadProgress, ImageError, ImageRequirements },
    props: {
        meterNumber: {type: String, required: true}
    },
    computed: {
        currentMeter () {
            return this.$store.getters.meterImages.find(
                meter => meter.meterNumber === this.meterNumber
            );
        },
        uploadProgress () {
            return this.$store.getters.meterImages.find(
                meter => meter.meterNumber === this.meterNumber
            ).progress;
        },
        isUploading () {
            return this.uploadProgress !== 0 && this.uploadProgress !== 100;
        },
        isDownloading () {
            return this.uploadProgress === 100 && !this.currentMeter.thumb;
        },
        errorMessages () {
            return this.currentMeter.errors;
        },
        originalFileName () {
            return this.currentMeter.originalFileName;
        },
        uploadDisabled () {
            return this.isUploading || this.isDownloading;
        },
        label () {
            const numMeters = this.$store.getters
                .getMeteringItemsByMeterNumber(this.meterNumber).length;
            let label = numMeters === 1
                ? "Bild des Zählerstandes"
                : "Bild der Zählerstände";
            return label + " (optional)";
        }
    },
    methods: {
        // this needs to be an action
        onChange ()  {
            const fileObj = this.$refs.file.files[0];
            if (fileObj && fileObj.name) {
                this.$store.dispatch("UPLOAD_IMAGE", {
                    customerId: this.$store.getters.customerId,
                    customerNumber: this.$store.getters.customerNo,
                    meterNumber: this.meterNumber,
                    file: fileObj
                });
            }
        }
    }
}
</script>

<template>
    <section>
        <form-row :label="label">
            <template slot="input">
                <div
                    v-if="!isUploading"
                    class="image-upload">
                    <label
                        :for="`readingImage${meterNumber}`"
                        class="image-label"
                        :class="uploadDisabled ? 'disabled' : ''">
                        <input
                            type="file"
                            accept="image/*"
                            :id="`readingImage${meterNumber}`"
                            :name="`readingImage${meterNumber}`"
                            :disabled="uploadDisabled"
                            :aria-disabled="uploadDisabled"
                            ref="file"
                            @change="onChange()">
                        <span v-if="originalFileName && !isDownloading">{{ originalFileName }} <strong>✔</strong></span>
                        <span v-if="!originalFileName && !isDownloading">Bild auswählen</span>
                        <span v-if="isDownloading">Bitte warten...</span>
                    </label>
                    <label
                        :for="`readingImage${meterNumber}`"
                        class="image-icon">
                        <icon
                            v-if="uploadProgress !== 100 && !originalFileName"
                            name="add_photo" />
                        <icon
                            v-if="uploadProgress === 100 && originalFileName"
                            name="photo" />
                        <request-progressing v-if="isDownloading" />
                    </label>
                </div>
                <upload-progress
                    v-if="isUploading"
                    :percentage="uploadProgress" />
                <image-error
                    v-if="errorMessages.length"
                    :error-messages="errorMessages" />
            </template>
            <template slot="text">
                <meter-image-preview
                    :thumb="currentMeter.thumb"
                    :meter-number="meterNumber" />
            </template>
        </form-row>
        <form-row
            class="req"
            v-if="!currentMeter.thumb"
            label="Bild Voraussetzungen">
            <template slot="input">
                <image-requirements />
            </template>
        </form-row>
    </section>
</template>

<style lang="scss" scoped>
    .image-upload {
        display: flex;

        max-width: map-get($breakpoints, "small");
    }

    .image-label {
        display: block;

        width: 100%;
        max-width: calc(100% - 50px);
        padding: #{map-get($sizes, "base") * 0.5} #{map-get($sizes, "base") * 0.7};
        border: #{map-get($sizes, "border")} solid map-get($client, "inputBorder");
        border-right-width: 0;

        background-color: map-get($client, "meterImageLabelBg");
        color: map-get($client, "inputText");
        border-radius: map-get($client, "borderRadius") 0 0 map-get($client, "borderRadius");
        box-shadow:
            inset 2px 2px 0 map-get($client, "appBg"),
            inset -2px 2px 0 map-get($client, "appBg"),
            inset 2px -2px 0 map-get($client, "appBg"),
            inset -2px -2px 0 map-get($client, "appBg");
        cursor: pointer;

        line-height: #{map-get($sizes, "base") * 1.4 + 4px};

        &.disabled {
            cursor: not-allowed;
        }

        @media all and (min-width: #{map-get($breakpoints, "small") + 50px}) {
            width: #{map-get($breakpoints, "small") - 50px};
        }

        input[type=file] {
            display: none;
        }

        span strong {
            color: palette("state", "success");
        }
    }

    .image-icon {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 50px;
        padding: #{map-get($sizes, "base") * 0.5} 2px;
        border: #{map-get($sizes, "border")} solid map-get($client, "inputBorder");
        border-left-width: 0;
        //margin-left: auto;

        background-color: map-get($client, "meterImageLabelBg");
        color: map-get($client, "inputText");
        border-top-right-radius: map-get($client, "borderRadius");
        border-bottom-right-radius: map-get($client, "borderRadius");
        box-shadow:
            inset -2px 2px 0 map-get($client, "appBg"),
            inset -2px -2px 0 map-get($client, "appBg");
        cursor: pointer;

        line-height: 1.4;
        text-align: center;

        svg.circular {
            display: inline-block;

            width: 24px;
            height: 24px;
            margin-right: 0;
        }
    }

    .req {
        margin-top: -#{map-get($sizes, "base")};
    }
</style>
