<script>
/******************************************************************************
 * SUCCESS animation
 *****************************************************************************/
export default {
    props: {
        size: {type: Number, default: 128}
    },
    computed: {
        svgStyle () {
            return {
                width: this.size + "px",
                height: this.size + "px"
            };
        }
    }
}
</script>

<template>
    <svg
        viewBox="0 0 100 100"
        :style="svgStyle">
        <circle
            class="circle"
            cx="50"
            cy="50"
            r="47.5"
            stroke-width="5"
            stroke-linecap="round" />
        <path
            class="checkmark"
            d="M 22,52 l 20,20 l 35,-35"
            stroke-width="7"
            stroke-linecap="round"
            stroke-linejoin="round"
            fill="none" />
    </svg>
</template>

<style lang="scss" scoped>
    svg {
        width: 100%;
        height: 100%;
    }

    .checkmark {
        stroke: palette("state", "success");
        stroke-dasharray: 100;
        stroke-dashoffset: 100;

        animation: dash #{map-get($animation-speeds, "portly")} ease-in-out forwards;
        animation-delay: 0.5s;
    }

    .circle {
        fill: lighten(palette("state", "success"), 60%);
        stroke: palette("state", "success");
        stroke-dasharray: 300;
        stroke-dashoffset: 300;

        animation: dash #{map-get($animation-speeds, "portly")} ease-in-out forwards;
        animation-delay: 0.5s;
    }

    @keyframes dash {
        to {
            stroke-dashoffset: 0;
        }
    }
</style>
