<script>
/******************************************************************************
 * Page Status
 *****************************************************************************/
import Icon from "@/components/Icon/Icon";
export default {
    props: {
        pageName: { type: String, required: true}
    },
    components: {
        Icon
    },
    computed: {
        sectionStatus () {
            return this.$store.getters.sectionStatusByName(this.pageName);
        },
        statusIcon () {
            switch(this.sectionStatus) {
                case "locked":
                    return "lock";
                case "error":
                    return "warning";
                case "ok":
                    return "done";
                case "":
                case "default":
                    return "";
            }
        }
    }
}
</script>

<template>
    <div
        v-if="statusIcon"
        class="status"
        :class="sectionStatus">
        <icon
            :name="statusIcon"
            size="1" />
    </div>
</template>

<style lang="scss" scoped>
    .status {
        position: absolute;
        top: 2px;
        right: 2px;

        width: 20px;
        height: 20px;

        > svg {
            display: block;
        }

        &.error { color: map-get($client, "navBarStatusError"); }
        &.locked { color: map-get($client, "navBarStatusLocked"); }
        &.ok { color: map-get($client, "navBarStatusOk"); }
    }
</style>
