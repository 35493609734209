<script>
/******************************************************************************
 * METERS Component
 * this lists available meters
 *****************************************************************************/
import PageSection from "@/components/PageSection/PageSection";
import Meter from "./Meter";
export default {
    computed: {
        meterNumbers () { return this.$store.getters.meters.map(meter => meter.meterNumber); },
        // we need to filter meters since there can be duplicates
        // HT/NT meters have same ZAE_NR / MES_ID, different KNZ_ZAEHLWERKNR
        meters () {
            let filteredMeters = [];
            this.meterNumbers.forEach(meter => {
                if (!filteredMeters.includes(meter)) {
                    filteredMeters.push(meter);
                }
            });
            return filteredMeters;
        }
    },
    components: {
        PageSection,
        "ivu-meter": Meter
    }
}
</script>

<template>
    <page-section hdl="Ihre Zähler">
        <ivu-meter
            v-for="meter in meters"
            :key="meter"
            class="reading-meter"
            :meter-number="meter" />
    </page-section>
</template>

<style lang="scss" scoped>
    .reading-meter {
        position: relative;

        padding: #{map-get($sizes, "base") / 2};
        border: map-get($sizes, "border") solid map-get($client, "dividerBg");
        margin: 0 0 #{map-get($sizes, "base") / 1.2} 0;

        @include respond-to("medium") {
            padding: #{map-get($sizes, "base")};
            margin: 0 0 #{map-get($sizes, "base") * 1.2} 0;
        }
    }
</style>
