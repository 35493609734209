<script>
/******************************************************************************
 * Panel Component
 *****************************************************************************/
export default {
    props: {
        hdl: {type: String, default: ""}
    },
    computed: {}
}
</script>

<template>
    <div class="panel">
        <header v-if="hdl">
            <h2>{{ hdl }}</h2>
        </header>
        <slot />
    </div>
</template>

<style lang="scss" scoped>
    .panel {
        padding: 1rem;
        margin: 0;

        background-color: map-get($client, "panelBg");
    }

    h2 {
        padding: 0 0 1rem 0;
        border-bottom: map-get($sizes, "border") solid map-get($client, "panelDivider");
        margin: 0 0 1rem 0;

        color: palette("brand");

        font-size: #{map-get($sizes, "base") * 1.3};
        font-weight: 300;
    }
</style>
