<script>
/******************************************************************************
 * Nav Bar
 *****************************************************************************/
import Icon from "@/components/Icon/Icon";
import PageLink from "./PageLink";
export default {
    data: function () {
        return {
            pages: [
                {name: "auth", title: "Anmeldung", icon: "key"},
                {name: "meterReading", title: "Zählerstände", icon: "meter"},
                {name: "confirm", title: "Überprüfen", icon: "check"},
                {name: "success", title: "Fertig", icon: "done_all"},
            ]
        }
    },
    components: {
        Icon,
        PageLink
    }
}
</script>

<template>
    <nav class="navbar clearfix">
        <page-link
            v-for="page in pages"
            :key="page.name"
            :name="page.name"
            :title="page.title"
            :icon="page.icon" />
    </nav>
</template>

<style lang="scss" scoped>
    .navbar {
        height: map-get($sizes, "navBarHeight");
    }
</style>
