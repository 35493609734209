<script>
/******************************************************************************
 * Page Link
 *****************************************************************************/
import Icon from "@/components/Icon/Icon";
import LinkStatus from "./LinkStatus";
export default {
    props: {
        name: {type: String, required: true},
        title: {type: String, required: true},
        icon: {type: String, required: true}
    },
    components: {
        Icon,
        LinkStatus
    },
    computed: {
        linkClass () {
            return this.lockedRoute ? "step step--locked" : "step";
        },
        lockedRoute () {
            return this.$store.getters.sections
                .find(sec => sec.name === this.name).status === "locked";
        }
    }
}
</script>

<template>
    <router-link
        :class="linkClass"
        :to="{name}"
        :aria-disabled="lockedRoute">
        <icon
            class="step__icon"
            :name="icon" />
        <span class="step__text">{{ title }}</span>
        <link-status :page-name="name" />
    </router-link>
</template>


<style lang="scss" scoped>
    .step {
        display: block;
        position: relative;
        float: left;

        width: calc(25% - 2px);
        height: map-get($sizes, "navBarHeight");
        margin-right: 2px;

        background-color: map-get($client, "navBarBg");
        color: map-get($client, "navBarText");

        text-align: center;
        text-decoration: none;

        transition: background-color map-get($animation-speeds, "fast") linear;

        &:last-child {
            width: 25%;
            margin-right: 0;
        }

        &:hover {
            background-color: darken(map-get($client, "navBarBg"), 7%);
        }

        &__text {
            display: none;

            height: map-get($sizes, "navBarHeight");

            line-height: map-get($sizes, "navBarHeight");

            @include respond-to("medium") { display: inline-block; }
        }

        &__icon {
            margin: 7px 0 0 0;

            @include respond-to("medium") { margin: -4px 5px 0 0; }
        }

        &--locked {
            color: map-get($client, "navBarLockedText");

            cursor: not-allowed;

            font-style: italic;
        }
    }

    .router-link-exact-active {
        background: map-get($client, "navBarActiveBg");
        color: map-get($client, "navBarActiveText");

        &:hover {
            background-color: darken(map-get($client, "navBarActiveBg"), 7%);
        }
    }
</style>
