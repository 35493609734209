/***********************************************************************************************************************
 *
 * Vuex store
 *
 **********************************************************************************************************************/
import Vue from "vue";
import Vuex from "vuex";
import actions from "./actions";
import mutations from "./mutations";
import {getState} from "../handlers/persistantState";

Vue.use(Vuex);

// we need the defaultState in the mutations so we can reset the state
export const defaultState = () => {
    return {
        // app state
        sections: [
            // status = "" || "locked" || "error" || "ok". DO NOT TOUCH
            { name: "auth", status: "" },
            { name: "meterReading", status: "locked" },
            { name: "confirm", status: "locked" },
            { name: "success", status: "locked" }
        ],
        requesting: false,
        authError: "",
        serverError: "",
        // customer data we got from the user
        customerNo: "",
        meterNo: "",
        // customer data we got from db
        customerId: "",
        clientId: "",
        customerDetails: {},
        // meters
        meters: [],
        // reading data
        dateType: "global", // "individual" || "global"
        globalReadingDate: new Date().toISOString(), // default
        readingReason: "Jahresablesung", // value, not label. must match ReadingReason.vue
        meterReadings: [], // array of meter readings.
        printFile: "",
        // image upload function
        meterImages: [],
        allowImageUpload: false,
        allowedFileTypes: [],
        imageMaxFileSize: 0,
        // email submission function
        allowEmailSubmission: false,
        email: "",
        emailErrors: [],
        emailSubmitting: false,
        emailSubmissionDone: false
    };
};

const store = new Vuex.Store({
    state: getState(), // either from session storage or initial state
    getters: {
        // app state
        sections: state => state.sections,
        sectionStatusByName: state => name =>
            state.sections.find(section => section.name === name).status || "",
        requesting: state => state.requesting,
        authError: state => state.authError,
        serverError: state => state.serverError,
        // customer data we got from the user
        customerNo: state => state.customerNo,
        meterNo: state => state.meterNo,
        // customer data we got from db
        customerId: state => state.customerId,
        clientId: state => state.clientId,
        customerDetails: state => state.customerDetails,
        // meters
        meters: state => state.meters || [],
        getMeterByNumbers: state => meter =>
            state.meters.find(
                reading =>
                    meter.meterNumber === reading.meterNumber
                    && meter.meterItemNumber === reading.meterItemNumber
            ),
        //getMeteringItems: state =>
        getMeteringItemsByMeterNumber: state => mesId =>
            state.meters.filter(meter => meter.meterNumber === mesId),
        // reading data
        dateType: state => state.dateType,
        globalReadingDate: state => state.globalReadingDate,
        readingReason: state => state.readingReason || "",
        meterReadings: state => state.meterReadings,
        implausibleReasonByMeter: state => meter =>
            state.meterReadings.find(
                reading =>
                    meter.meterNumber === reading.meterNumber
                    && meter.meterItemNumber === reading.meterItemNumber
            ).implausibleReason || "",
        implausibleReasonOtherByMeter: state => meter =>
            state.meterReadings.find(
                reading =>
                    meter.meterNumber === reading.meterNumber
                    && meter.meterItemNumber === reading.meterItemNumber
            ).implausibleReasonOther || "",
        printFile: state => state.printFile,
        // image upload function
        meterImages: state => state.meterImages,
        allowImageUpload: state => state.allowImageUpload,
        allowedFileTypes: state => state.allowedFileTypes,
        imageMaxFileSize: state => state.imageMaxFileSize,
        // email submission function
        allowEmailSubmission: state => state.allowEmailSubmission,
        email: state => state.email,
        emailErrors: state => state.emailErrors,
        emailSubmitting: state => state.emailSubmitting,
        emailSubmissionDone: state => state.emailSubmissionDone
    },
    mutations,
    actions
});

export default store;
