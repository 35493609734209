<script>
/******************************************************************************
 * Reading Data Component
 * this component shows meter reading reason and global reading date
 *****************************************************************************/
import GlobalReadingDate from "./GlobalReadingDate";
import ReadingReason from "./ReadingReason";
import PageSection from "@/components/PageSection/PageSection";
export default {
    components: {
        GlobalReadingDate,
        PageSection,
        ReadingReason
    }
}
</script>

<template>
    <page-section
        hdl="Ablesedaten"
        class="reading-data">
        <reading-reason />
        <global-reading-date />
    </page-section>
</template>

<style lang="scss" scoped>
    .reading-data {
        margin: map-get($sizes, "base") 0;
    }
</style>
