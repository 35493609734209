/******************************************************************************
 * Vue-Router
 *****************************************************************************/
import Vue from "vue";
import Router from "vue-router";
import Auth from "@/pages/Auth/Auth";
import store from "@/store";
import MeterReading from "@/pages/MeterReading/MeterReading";
import Confirm from "@/pages/Confirm/Confirm";
import Success from "@/pages/Success/Success";

Vue.use(Router);

/*
 * global navigation guard
 */
const beforeEnter = (to, from, next) => {
    const routeLocked = store.getters.sections
        .find(sec => sec.name === to.name).status === "locked";
    console.log(`nav request from ${from.name} to ${to.name} => ${!routeLocked}`);
    !routeLocked && next();
};

let router = new Router({
    routes: [
        {
            name: "auth",
            path: "/",
            component: Auth,
            beforeEnter
        },
        {
            name: "meterReading",
            path: "/meterReading",
            component: MeterReading,
            beforeEnter
        },
        {
            name: "confirm",
            path: "/confirm",
            component: Confirm,
            beforeEnter
        },
        {
            name: "success",
            path: "/success",
            component: Success,
            beforeEnter
        },
        {
            path: "*",
            redirect: { name: "auth" }
        }
    ],
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    }
});

//router.beforeEach((to, from, next) => {
//    const page = document.querySelector(".page");
//    console.log(to, page && page.scrollTop);
//    next();
//});

export default router;
