<script>
/******************************************************************************
 * Common (simple) Dropdown Component
 *****************************************************************************/
export default {
    data() {
        return {
            selectedOption: {name: "", value:""},
            showMenu: false,
        }
    },
    props: {
        options: {
            type: [Array, Object],
            default: () => { return []; }
        },
        selectedValue: {
            type: String,
            default: ""
        }
    },
    mounted() {
        if (this.selectedValue) {
            this.selectedOption = this.options
                .find(option => option.value === this.selectedValue);
        }
    },
    computed: {
        caretClass () { return this.showMenu ? "caret open" : "caret"; }
    },
    methods: {
        updateOption(option) {
            this.selectedOption = option;
            this.showMenu = false;
            this.$emit('updateOption', this.selectedOption);
        },
        toggleMenu() { this.showMenu = !this.showMenu; },
        optionClass (option) {
            return this.selectedOption && option.value === this.selectedOption.value
                ? "selected"
                : "";
        }
    }
}
</script>

<template>
    <ul class="btn-group">
        <li
            @click="toggleMenu()"
            class="dropdown-toggle"
            v-if="selectedOption && selectedOption.value"
            aria-label="Gewählte Option">
            {{ selectedOption.name }}
            <span :class="caretClass" />
        </li>
        <li
            @click="toggleMenu()"
            class="dropdown-toggle"
            v-if="!selectedOption || !selectedOption.value">
            Bitte wählen
            <span :class="caretClass" />
        </li>
        <ul
            class="dropdown-menu"
            role="radiogroup"
            aria-label="Verfügbare Optionen"
            :class="{ 'hide' : !showMenu}">
            <li
                v-for="option in options"
                :key="option.value"
                role="radio"
                :class="optionClass(option)"
                :aria-checked="selectedOption.value === option.value"
                @click.prevent="updateOption(option)"
                aria-label="Diese Option wählen">
                {{ option.name }}
            </li>
        </ul>
    </ul>
</template>

<style lang="scss" scoped>
    // configure component
    $selectBg: map-get($client, "selectBg");
    $selectText: map-get($client, "selectText");
    $border: map-get($sizes, "border") solid map-get($client, "dividerBg");
    $base: map-get($sizes, "base");
    $maxWidth: map-get($breakpoints, "small");
    $animSpeed: map-get($animation-speeds, "fast");
    $black: palette("grey", "black");
    $hopverBg: palette("grey", "astral");
    $hoverColor: palette("brand");

    .btn-group {
        display: inline-block;
        position: relative;

        width: 100%;
        max-width: $maxWidth;
        height: 38px;
        padding: 0;
        margin: 0;

        list-style: none;

        line-height: 38px;

        vertical-align: middle;

        a:hover {
            text-decoration: none;
        }
    }

    .dropdown-toggle {
        min-width: 160px;
        padding: 0 #{$base * 0.7};
        border: 0;

        background-color: $selectBg;
        color: $selectText;
        border-radius: 0;
        box-shadow: none;

        line-height: 38px;

        transition: background $animSpeed ease-out;

        &:hover {
            background-color: darken($selectBg, 10%);
            color: $black;
            outline: 0;
            border-color: darken($selectBg, 15%);

            cursor: pointer;
        }
    }

    .hide {
        display: none;
    }

    .dropdown-menu {
        position: absolute;
        top: 100%;
        right: 0;
        left: 0;
        z-index: 1000;

        padding: 0;
        border: $border;
        margin: 2px 0 0 0;

        background-color: #fff;
        background-clip: padding-box;
        list-style: none;
        border-radius: 4px;
        box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.175);

        font-size: 14px;
        text-align: left;
    }

    .dropdown-menu > li {
        display: block;
        position: relative;
        clear: both;

        overflow: hidden;
        width: 100%;
        padding: #{$base * 0.5} #{$base * 0.7};
        border-bottom: $border;
        margin: 0;

        color: $selectText;
        cursor: pointer;

        font-weight: normal;
        line-height: 1.6;
        text-decoration: none;
        white-space: nowrap;

        &:last-child { border-bottom-width: 0; }

        &.please-select {
            cursor: not-allowed;

            font-style: italic;
        }

        &.selected,
        &:hover {
            background: $hopverBg;
            color: $hoverColor;
        }
    }

    .caret {
        $caretSize: #{$base / 3};

        display: block;
        position: absolute;
        top: 50%;
        right: #{$base * 0.7};

        width: 0;
        height: 0;
        border-top: $caretSize dashed;
        border-right: $caretSize solid transparent;
        border-left: $caretSize solid transparent;
        margin-left: 2px;

        transform: translateY(-50%);

        &.open {
            border-bottom: $caretSize dashed;
            border-top-width: 0;
        }
    }
</style>
