<script>
/******************************************************************************
 * Email Submission Done
 *****************************************************************************/
import Icon from "@/components/Icon/Icon";
export default {
    components: { Icon }
}
</script>

<template>
    <article class="done">
        <icon name="done" />
        Vielen Dank!
    </article>
</template>

<style lang="scss" scoped>
    .done {
        display: flex;
        align-items: center;

        padding: #{map-get($sizes, "base") / 2};
        border: #{map-get($sizes, "border") * 3} solid palette("state", "success");

        color: palette("state", "success");
        border-radius: map-get($client, "borderRadius");

        font-weight: 500;

        svg {
            display: block;

            width: 32px;
            height: 32px;
            margin: 0 #{map-get($sizes, "base") / 2};

            color: palette("state", "success");
        }
    }
</style>
