<script>
/******************************************************************************
 * Radio Group
 * this component list radio buttons and styles them
 *****************************************************************************/
export default {
    data: function () {
        return {
            radioValue: this.radioSettings.find(radio => radio.checked)
                && this.radioSettings.find(radio => radio.checked).value
        }
    },
    props: {
        // radioSeetings needs to be an array of objects with name, id, value, label props.
        radioSettings: {
            type: Array,
            default: () => { return []; }
        }
    },
    watch: {
        // whenever radio value changes, this function will run
        radioValue: function () {
            return this.$emit("change-radio-value", this.radioValue);
        }
    }
}
</script>

<template>
    <ul
        role="radiogroup"
        class="radio-list"
        aria-label="Verfügbare Optionen">
        <li
            v-for="radioBtn in radioSettings"
            :key="radioBtn.id"
            class="list-item">
            <input
                type="radio"
                :name="radioBtn.name"
                :id="radioBtn.id"
                :value="radioBtn.value"
                v-model="radioValue">
            <label :for="radioBtn.id">
                {{ radioBtn.label }}
            </label>
            <div class="check" />
        </li>
    </ul>
</template>

<style lang="scss" scoped>
    $base: map-get($sizes, "base");
    $radioInnerSize: #{map-get($sizes, "base") * 0.7};
    $animSpeed: map-get($animation-speeds, "fast");
    $radioColor: map-get($client, "radioColor");
    $hoverColor: map-get($client, "radioColorHover");
    $selectedColor: map-get($client, "radioColorSelected");
    $hoverBg: palette("grey", "mystic");
    $maxWidth: map-get($breakpoints, "small");

    .radio-list {
        max-width: $maxWidth;
        padding: 0;
        margin: 0;

        list-style: none;
    }

    .list-item {
        $rowHeight: #{$base * 1.4 + 12px + 4px};
        $radioSize: #{$base * 1.6};

        position: relative;

        height: $rowHeight;
        margin-bottom: 0.5rem;

        line-height: $rowHeight;

        &:last-child { margin-bottom: 0; }

        > label {
            display: block;

            padding-left: #{$base * 2.8};

            color: $radioColor;
            cursor: pointer;

            transition:
                color $animSpeed linear,
                background-color $animSpeed linear;

            &:hover {
                background-color: $hoverBg;
                color: $hoverColor;
            }
        }

        input[type=radio] {
            position: absolute;
            opacity: 0;

            &:checked ~ .check::before {
                background-color: $selectedColor;
            }

            &:checked ~ label {
                color: $selectedColor;
            }

            // holy shit, this really works.
            &:not(:checked) ~ label:hover ~ .check {
                border-color: $hoverColor;
            }
        }

        .check {
            position: absolute;
            top: 50%;
            left: #{$base * 0.45};

            width: $radioSize;
            height: $radioSize;
            border: 4px solid map-get($client, "radioColor");

            transform: translateY(-50%);

            border-radius: 50%;
            pointer-events: none;

            transition: border-color $animSpeed linear;

            &::before {
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;

                width: $radioInnerSize;
                height: $radioInnerSize;

                transform: translate(-50%, -50%);

                border-radius: 50%;

                content: " ";

                transition: background-color $animSpeed linear;
            }
        }
    }
</style>
