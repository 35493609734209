<script>
/******************************************************************************
 * METER Image Upload Progress
 *****************************************************************************/
export default {
    props: {
        percentage: {type: Number, required: true}
    },
    computed: {
        shrinkerWidth () {
            const pctWidth = 100 - this.percentage;
            return {
                width: pctWidth + "%"
            }
        }
    }
}
</script>

<template>
    <div
        class="wrapper"
        title="Übertrage Bild zum Server, bitte warten ...">
        <div class="upload-progress">
            <div class="bar">
                <div class="value">{{ percentage }}%</div>
            </div>
            <div
                class="shrinker"
                :style="shrinkerWidth" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
    $height: 16px;

    .wrapper {
        display: flex;
        align-items: center;

        height: #{map-get($sizes, "base") * 1.4 + 12px + 4px};
    }

    .upload-progress {
        display: flex;
        position: relative;
        align-items: center;

        width: 100%;
        max-width: map-get($breakpoints, "small");
        height: $height;
        padding: 2px;
        border: #{map-get($sizes, "border")} solid map-get($client, "inputBorder");

        background: palette("grey", "astral");
        border-radius: map-get($client, "borderRadius");
        box-shadow:
            inset 2px 2px 0 map-get($client, "appBg"),
            inset -2px 2px 0 map-get($client, "appBg"),
            inset 2px -2px 0 map-get($client, "appBg"),
            inset -2px -2px 0 map-get($client, "appBg");
    }

    .bar {
        position: relative;

        width: 100%;
        height: 100%;

        background: linear-gradient(to right, palette("state", "error"), palette("state", "warning"), palette("state", "success"));
        border-radius: map-get($client, "borderRadius");
    }

    .value {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;

        transform: translate(-50%, -50%);

        font-weight: 600;
        text-shadow:
            1px 1px palette("grey", "white"),
            -1px 1px palette("grey", "white"),
            1px -1px palette("grey", "white"),
            -1px -1px palette("grey", "white");
    }

    .shrinker {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;

        width: 100%;

        background: palette("grey", "astral");

        transition: width map-get($animation-speeds, "fast") linear;
    }
</style>
