/*
 * this config file is imported into the client and
 * lands in the production bundle.
 */

import pkg from "../../package";

const APP_NAME = pkg.app.name;
const APP_SHORT_NAME = pkg.app.shortName;
const APP_DESCRIPTION = pkg.app.description;
const CLIENT_FULL = pkg.app.clientFull;
const CLIENT_SHORT = pkg.app.clientShort;
const CLIENT_URL = pkg.app.clientUrl;
const LENGTH_IMPLAUSIBLE_REASON_OTHER = 200;

export {
    APP_NAME,
    APP_SHORT_NAME,
    APP_DESCRIPTION,
    CLIENT_FULL,
    CLIENT_SHORT,
    CLIENT_URL,
    LENGTH_IMPLAUSIBLE_REASON_OTHER
};
