<script>
/******************************************************************************
 * Navigation Row Component
 * styling component with slots
 *****************************************************************************/
export default {
    computed: {
        hasLeft () { return !!this.$slots.left; },
        hasRight () { return !!this.$slots.right; },
    }
}
</script>

<template>
    <ul class="nav clearfix">
        <li
            class="prev"
            v-if="hasLeft">
            <slot name="left" />
        </li>
        <li
            class="next"
            v-if="hasRight">
            <slot name="right" />
        </li>
    </ul>
</template>

<style lang="scss" scoped>
    .nav {
        padding: 0;
        margin: 0;

        list-style: none;

        > li {
            float: right;

            &.prev button,
            &.next button,
            &.next a {
                padding: 0 #{map-get($sizes, "base") * 0.8};
                border: map-get($sizes, "border") solid transparent;

                border-radius: map-get($client, "borderRadius");
                box-shadow:
                    inset 2px 2px 0 map-get($client, "appBg"),
                    inset -2px 2px 0 map-get($client, "appBg"),
                    inset 2px -2px 0 map-get($client, "appBg"),
                    inset -2px -2px 0 map-get($client, "appBg");

                cursor: pointer;

                font-size: map-get($sizes, "base");
                line-height: #{map-get($sizes, "base") * 2.5};

                transition:
                    background-color map-get($animation-speeds, "fast") linear,
                    border-color map-get($animation-speeds, "fast") linear,
                    color map-get($animation-speeds, "fast") linear;

                &[disabled] {
                    opacity: 0.8;

                    cursor: not-allowed;
                }

                @include respond-to("small") { padding: 0 #{map-get($sizes, "base") * 1.2}; }
            }

            &.prev {
                float: left;

                button {
                    padding-left: 0.5rem;

                    background-color: map-get($client, "buttonBgAlt");
                    color: map-get($client, "buttonTextAlt");
                    border-color: map-get($client, "buttonBgAlt");

                    &:hover,
                    &:focus {
                        background-color: darken(map-get($client, "buttonBgAlt"), 7%);
                        color: palette("grey", "white");
                        outline: 0;
                        border-color: darken(map-get($client, "buttonBgAlt"), 7%);
                    }

                    @include respond-to("small") { padding-left: map-get($sizes, "base"); }

                    @extend %clearfix;
                }
            }

            &.next {
                text-align: right;

                button,
                a {
                    padding-right: 0.5rem;

                    background-color: map-get($client, "buttonBg");
                    color: map-get($client, "buttonText");
                    border-color: map-get($client, "buttonBg");

                    &:hover,
                    &:focus {
                        background-color: darken(map-get($client, "buttonBg"), 15%);
                        color: palette("grey", "white");
                        outline: 0;
                        border-color: darken(map-get($client, "buttonBg"), 15%);
                    }

                    @include respond-to("small") { padding-right: map-get($sizes, "base"); }
                }

                button { @extend %clearfix; }

                a {
                    display: inline-block;

                    text-decoration: none;
                }
            }
        }
    }
</style>
